import { createStore } from 'vuex'

export default createStore({
  state: {
    verified: Boolean(localStorage.getItem('verified') || false),
    nsfw: Boolean(localStorage.getItem('nsfw') || false),
    selectedCategories: [],
    selectedRatings: [],
    groupVersions: true
  },
  mutations: {
    setVerified (state, v) {
      state.verified = v
      v ? localStorage.setItem('verified', true) : localStorage.removeItem('verified')
    },
    setNSFW (state, v) {
      state.nsfw = v
      v ? localStorage.setItem('nsfw', true) : localStorage.removeItem('nsfw')
    },
    setSelectedCategories (state, v) {
      state.selectedCategories = v
    },
    setSelectedRatings (state, v) {
      state.selectedRatings = v
    }
  }
})
