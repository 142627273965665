import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }, {
    path: '/art/:page?',
    name: 'art',
    component: () => import('../views/art/Art.vue')
  }, {
    path: '/artview/:id',
    name: 'artview',
    component: () => import('../views/art-view/ArtView.vue')
  }, {
    path: '/commission/:page?',
    name: 'commission',
    component: () => import('../views/commission/Commission.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
