<template>
  <header>
    <div class='top'>
      <router-link to='/' class='logo'>
        <Logo/>
      </router-link>
      <div class='warning'>
        <span>Under construction!</span>
      </div>
      <CNav/>
      <CMenuButton ref='menuButton' @change='menuButtonChange'/>
    </div>
    <div class='bg' :class='{ visible: menuOpen }' @click='setMenu(false)'/>
    <div class='side' :class='{ open: menuOpen }'>
      <CNav/>
      <div class='options'>
        <span class='title'>Options</span>
        <div class='list'>
          <CInputCheckbox v-model='nsfw' label='NSFW' @changed='updateNSFW'/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/images/logo.svg?inline'
import CNav from './Nav.vue'
import CMenuButton from './menu-button/MenuButton.vue'
import CInputCheckbox from '@/components/input/checkbox/InputCheckbox.vue'

export default {
  name: 'CHeader',
  components: {
    Logo,
    CNav,
    CMenuButton,
    CInputCheckbox
  },
  watch: {
    '$route' () {
      if (this.menuOpen) this.setMenu(false)
    }
  },
  data () {
    return {
      menuOpen: false,
      nsfw: this.$store.state.nsfw
    }
  },
  methods: {
    menuButtonChange (v) {
      this.menuOpen = v
    },
    setMenu (v) {
      v ? this.$refs.menuButton.openMenu() : this.$refs.menuButton.closeMenu()
    },
    updateNSFW (v) {
      this.$store.commit('setNSFW', v)
    }
  }
}
</script>

<style scoped lang='sass'>
@import 'header'
</style>
