<template>
  <nav>
    <div class='links'>
      <router-link to='/'><span>Home</span></router-link>
      <router-link to='/art' :class='{ "router-link-active": $route.fullPath.startsWith("/artview") }'><span>Art</span></router-link>
      <router-link to='/commission' :class='{ "router-link-active": $route.fullPath.startsWith("/commission") }'><span>Commission</span></router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CNav',
  data () {
    return {
      open: false
    }
  }
}
</script>

<style scoped lang='sass'>
@import 'header'
</style>
