<template>
  <label class='input-checkbox' :class='{ selected: modelValue, disabled: disabled }' @click='handleInput'>
    <input type='checkbox' :checked='modelValue'/>
    <div class='box'/>
    <div class='box color' :style='{ backgroundColor: `#${this.color}` }'/>
    <span>{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'CInputCheckbox',
  props: {
    disabled: Boolean,
    modelValue: Boolean,
    label: String,
    color: String
  },
  methods: {
    emit (v) {
      this.$emit('update:modelValue', v)
      this.$emit('changed', v)
    },
    handleInput (e) {
      e.preventDefault()
      if (!this.disabled) this.emit(!this.modelValue)
    }
  }
}
</script>

<style scoped lang='sass'>
@import 'input-checkbox'
</style>
