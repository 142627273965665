<template>
  <div class='home'>
    <div class='logo'><img src='@/assets/images/logoi.png'/></div>
    <div class='links'>
      <div class='warning'>
        <span><b>Warning!</b></span>
        <span>This website contains adult furry content!</span>
      </div>
      <a class='link fa' href='https://www.furaffinity.net/user/zemble' target='_blank' title='Fur Affinity'>
        <div>
          <div class='icon'><IconFA/></div>
          <span>Fur Affinity</span>
        </div>
      </a>
      <a class='link bluesky' href='https://bsky.app/profile/zemble.bsky.social' target='_blank' title='Bluesky'>
        <div>
          <div class='icon'><IconBluesky/></div>
          <span>Bluesky</span>
        </div>
      </a>
      <a class='link picarto' href='https://picarto.tv/zemble' target='_blank' title='Picarto'>
        <div>
          <div class='icon'><IconPicarto/></div>
          <span>Picarto</span>
        </div>
      </a>
      <a class='link twitch' href='https://twitch.tv/zemblearts' target='_blank' title='Twitch'>
        <div>
          <div class='icon'><IconTwitch/></div>
          <span>Twitch</span>
        </div>
      </a>
      <a class='link youtube' href='https://www.youtube.com/@zemblearts' target='_blank' title='YouTube'>
        <div>
          <div class='icon'><IconYouTube/></div>
          <span>YouTube</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import IconFA from '@/assets/images/fa.svg?inline'
import IconBluesky from '@/assets/images/bluesky.svg?inline'
import IconPicarto from '@/assets/images/picarto.svg?inline'
import IconTwitch from '@/assets/images/twitch.svg?inline'
import IconYouTube from '@/assets/images/youtube.svg?inline'

export default {
  name: 'VHome',
  components: {
    IconFA,
    IconBluesky,
    IconPicarto,
    IconTwitch,
    IconYouTube
  },
  mounted () {
    document.title = 'Zemble | Home'
  }
}
</script>

<style scoped lang='sass'>
@import 'home'
</style>
