<template>
  <div class='menu-button' :class='{ open: opened, closed: closed }' @click='click'>
    <div class='line l1'/>
    <div class='line l2'/>
    <div class='line l3'/>
  </div>
</template>

<script>
export default {
  name: 'CMenuButton',
  data () {
    return {
      opened: false,
      closed: false
    }
  },
  methods: {
    click () {
      this.opened ? this.closeMenu() : this.openMenu()
    },
    openMenu () {
      this.opened = true
      this.closed = false
      this.$emit('change', true)
    },
    closeMenu () {
      this.opened = false
      this.closed = true
      this.$emit('change', false)
    }
  }
}
</script>

<style scoped lang='sass'>
@import 'menu-button'
</style>
