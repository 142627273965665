<template>
  <CHeader/>
  <main>
    <router-view v-slot='{ Component }'>
      <transition name='fade'>
        <component :is='Component'/>
      </transition>
    </router-view>
  </main>
</template>

<script>
import CHeader from '@/components/header/Header.vue'

export default {
  name: 'App',
  components: {
    CHeader
  }
}
</script>

<style lang='sass'>
@import 'assets/sass/global'
</style>
